
/*
NOTES:
	-Core version control:
		-Frontend: in /bREST/core/version.js
		-Server:   in /bREST/bREST_base::VERSION & /bREST/version.info
	-Custom version control:
		-Frontend: in /custom/App.version.js
		-Server:   in bREST_Custom::CUSTOM_VERSION
*/

export default "4.3";
